@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --color-primary: #ec4755;
  --color-secondary: #a12c34;
  --color-tertiaty: #99a0a3;
}

a { 
  @apply text-red-700;
}

a:hover {
  @apply border-b-2 border-red-700;
}



.content p {
  @apply my-6;
}

.content ul {
  @apply my-6;
}

.animatedBackground {
  background: linear-gradient(-45deg, #3A3939, #3A3939, #3A3939, #3A3939, #d9d9d952, #3A3939, #3A3939, #3A3939, #3A3939, #3A3939, #3A3939, #3A3939);
  background-size: 400% 400%;
  -webkit-animation: Gradient 3s infinite;
  -moz-animation: Gradient 8s infinite;
  animation: Gradient 1.5s ease-out infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 100%
  }
}
