.get-started-input-container-hidden {
    position: absolute;
    left: -100px;
    opacity: 0;
    margin-top: 30px;
    transition: 0.5s;
    display: 'flex';
    flex-direction: column;
    justify-content: center;
    width: 50px;
}
.get-started-input-container {
    position: absolute;
    left: 4%;
    opacity: 1;
    margin-top: 30px;
    transition: 0.5s;
    display: 'flex';
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
}
.get-started-input {
    align-self: center;
    width: 80%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid black;
    padding: 10px;
}


.get-started-button-active {
    transition: 0.3s;
    margin-top: 170px;
    align-items: center;
}

.get-started-button-ready {
    transition: 0.3s;
    margin-top: 50px;
}

.get-started-buttons {
    display: 'flex';
    flex-direction: row;
}

@media (max-width: 768px) {
    .get-started-input {

    }
    .comingSoon h1 {
      font-size: 58px;
    }
  }