.privacyPolicy .container {
    width: 100%;
    height: 100%;
}

.iframe {
    position: absolute; 
    height: 100%; 
    width: 100%;
    border: none
}