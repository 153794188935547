.comingSoon .container {
  width: 100%;
  margin: 15% auto;
}
.col-4 {
  width: 25%;
  float: left;
}

.comingSoon h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .comingSoon .container {
    width: 1100px;
  }
  .comingSoon h1 {
    font-size: 58px;
  }
}
