@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: '#222222';
}


:root {
  --color-primary: #ec4755;
  --color-secondary: #a12c34;
  --color-tertiaty: #99a0a3;
}

a { 
  @apply text-red-700;
}

a:hover {
  @apply border-b-2 border-primary;
  cursor: pointer
}



.content p {
  @apply my-6;
}

.content ul {
  @apply my-6;
}

body {
    margin: 0;
}

.overlay-container {
    color: #fff;
    font-family: "Roboto Condensed", sans-serif;
    background-image: url(https://images.unsplash.com/photo-1508389377389-b8221c0bcc9e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80);
    background-position: top top;
    background-size: cover;
    margin: 0;
    height: 100vh;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(6,118,141);
  background: linear-gradient(
    0deg,
    rgba(6,118,141, 1) 0%,
    rgba(6,118,141, 1) 50%,
    rgba(6,118,141, 0.6) 100%
  );
}

.transition-300 {
  transition: 0.3s;
}

input[type="checkbox"] {
	appearance: none;
	-webkit-appearance: none;

	width: 1.6rem;
	height: 1.6rem;
	border-radius: 1rem;
  border: 3px solid rgb(255,255,255);
	background-color: rgba(255,255,255,0.25);
}

input[type="checkbox"]:checked {
  background-color: blue;
}

.appStoreButtons {
  background-color: blue;
  height: 200px;
}
