.accountVerified .container {
  color: #222222;
  width: 35%;
  border-radius: 20px;
  height: 85%;
  background-color: rgba(255,255,255,0.7);
  align-self: center;
}

.accountVerified .App {
    display: flex;
    justify-content: center;
}
.col-4 {
  width: 25%;
  float: left;
}

.accountVerified h1 {
    font-size: 38px;
    text-transform: none;
    line-height: 1;
}
